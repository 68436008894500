import React from 'react';
import { Route, Switch } from 'react-router';
import Navigation from './Components/Navigation/Navigation';
import Splash from './Components/Splash/Splash';
import Works from './Components/Works/Works';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Resume from './Components/Resume/Resume';

function App() {
  return (
    <>
    <Navigation/>
      <Switch>
          <Route exact path='/resume'>
            <Resume/>
          </Route>
          <Route exact path='/'>
            <Splash/>
          </Route>
          <Route exact path='/works'>
            <Works/>
          </Route>
          <Route exact path='/about'>
            <About/>
          </Route>
          <Route exact path='/contact'>
            <Contact/>
          </Route>
      </Switch>
    </>
  );
}

export default App;
