// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body, html {\n  margin: 0;\n  padding: 0;\n  height: 100vh; \n  width: 100vw; \n}\n\n* {\n  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n  text-decoration: none;\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;EACb,YAAY;AACd;;AAEA;EACE,2DAA2D;EAC3D,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":["@import url('./variables.css');\n\nbody, html {\n  margin: 0;\n  padding: 0;\n  height: 100vh; \n  width: 100vw; \n}\n\n* {\n  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n  text-decoration: none;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
