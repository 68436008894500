import React from 'react';
import './Works.css';

const Works = () => {
    return (
        <>
            <div className='headings'>
                <h1 className='big-heading'>View my projects</h1>
                <p className='big-heading-subheading'>These projects are hosted with Heroku, which may take a second to load! </p>
            </div>
            <div className='works-container'>
                <div className='each-project-container'>
                    <a rel='noreferrer' target='_blank' href='https://github.com/Machaelmus/Swiftly'>
                    <button className='repo-button-1'>Swiftly Repo</button>
                    </a>
                    <a rel='noreferrer' target='_blank' className='each-image' href="http://theswiftlyapp.herokuapp.com">
                        <div className='swiftly-image'>
                            <p className='title'>Swiftly</p>
                            <p className={'techs'}><span className='tech-span'>Technologies used:</span> Python, JavaScript, React, Redux, Flask and SQLAlchemy</p>
                            <br/>
                            <p className='description-site'>Swiftly is a social media website where users can create posts to share with anyone.</p>
                        </div>
                    </a>
                </div>
                <div className='each-project-container'>
                <a rel='noreferrer' target='_blank' href='https://github.com/IrinaAmzashvili/honeycomb'>
                <button className='repo-button-2'>Honeycomb Repo</button>
                </a>
                <a rel='noreferrer' target='_blank' className='each-image' href='http://app-honeycomb.herokuapp.com'>
                    <div className='honey-image'>
                        <p className='title'>Honeycomb</p>
                        <p className={'techs'}><span className='tech-span'>Technologies used:</span> Python, JavaScript, React, Redux, Flask and SQLAlchemy</p>
                        <br/>
                        <p className='description-site'>Honeycomb is a social based platform that allows users to easily see clubs located at their university.</p>
                    </div>
                </a>
                </div>
                <div className='each-project-container'>
                    <a rel='noreferrer' target='_blank' href='https://github.com/Run5/GoodTrails'>
                    <button className='repo-button-3'>GoodTrails Repo</button>
                    </a>
                    <a rel='noreferrer' target='_blank' className='each-image' href='https://good-trails.herokuapp.com/users/home'>
                        <div className='crashpad-image'>
                            <p className='title'>GoodTrails</p>
                            <p className={'techs'}><span className='tech-span'>Technologies used:</span> JavaScript, Express, Pug, Sequelize</p>
                            <br/>
                            <p className='description-site'>GoodTrails is a travel website dedicated to helping users find new trails anywhere in the country.</p>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Works;
