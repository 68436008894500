import React from 'react';
import Pdf from '../../MICHAEL_TUFO_RESUME_PDF.pdf'
import './Resume.css'

const Resume = () => {
    return (
        <div className='resume--container'>
            <h1 className='resume-header'>Resume</h1>
            <a href={Pdf} target="_blank" className='download-button'>Download PDF</a>
            <object className='resume' data={Pdf} type="application/pdf" height="1200px" width="1200px"></object>
        </div>
    )
}

export default Resume;
