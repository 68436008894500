import React from 'react';
import './About.css';


const About = () => {

    return (
        <div className='about-page-container'>
                <div className='about-description--container'>
                    <div className='about-face'></div>
                    <div className='description-proficient-container'>
                    <h1 className='about-title'>About me</h1>
                        <p className='about-dev'>I’m a Software engineer and motivated self-starter with a passion for building the web and creating new things. This passion sprung up at a small age starting with LEGO sets and has stuck close to me ever since. There's a lot of jobs out there, but if you were to ask "what gets me out of bed every day?" or "what drives me?", I would without a doubt answer you with software engineering, as there are few things that captivate me more.</p>
                        <p className='about-proficient'><span className='about-prof-bold--span'>I'm proficient in: </span>JavaScript, Python, React, Redux, Node, Express, Flask, Sequelize, PostgreSQL.</p>
                    </div>
                </div>
            </div>
    )
}

export default About;
