import React from 'react';
import './Contact.css';
import {AiFillLinkedin, AiFillGithub} from 'react-icons/ai';
import {FaAngellist} from 'react-icons/fa';

const Contact = () => {
    return (
        <div className='contact-whole'>
            <h1 className='contact-heading'>Let's Talk!</h1>
            <div className='contact-container'>
                <p className='contact-call-to-action'>I'm constantly on the lookout for new opportunities. If you'd like to contact me, please reach out!</p>
                <h1>Socials</h1>
                <div className='social-logos'>
                    <div className='social-logo-containers'>
                        <a rel='noreferrer' target='_blank' href='https://www.linkedin.com/in/michael-tufo-6b0386171/'>
                            <AiFillLinkedin className='icons linkedin'/>
                        </a>
                    </div>
                
                    <div className='social-logo-containers'>
                        <a rel='noreferrer' target='_blank' href='https://github.com/Machaelmus'>
                            <AiFillGithub className='icons github'/>
                        </a>
                    </div>
                
                    <div className='social-logo-containers'>
                        <a rel='noreferrer' target='_blank' href='https://angel.co/u/michael-tufo'>
                            <FaAngellist className='icons angelist'/>
                        </a>
                    </div>



                </div>
                <p className='dev-phone'>Reach me by phone at: <span>925-250-6769</span></p>
            </div>
        </div>
    )
}

export default Contact;
