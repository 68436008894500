import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';
import './navigation.css';

const Navigation = () => {
    const [openHamburger, setOpenHamburger] = useState(false);

    const openHamburgerMenu = () => openHamburger ? setOpenHamburger(true) : setOpenHamburger(false);
    const open = () => {
        setOpenHamburger(true)
        console.log(openHamburger)
    }
    const close = () => {
        setOpenHamburger(false)
        console.log(openHamburger);

    }
    return (
        <div className='navigation-container'>
            <div className='navigation-links--container'>
                <NavLink activeStyle={{fontWeight: '500'}} className='navigation-router-navlink-about' to='/' exact={true}>
                    <p className='navigation-links navigation-links-about'>Home</p>
                </NavLink>

                <NavLink activeStyle={{fontWeight: '500'}} className='navigation-router-navlink-about' to='/about'>
                    <p className='navigation-links navigation-links-about'>About</p>
                </NavLink>

                <NavLink activeStyle={{fontWeight: '500'}} className='navigation-router-navlink-resume' to='/resume'>
                    <p className='navigation-links'>Resume</p>
                </NavLink>

                <NavLink activeStyle={{fontWeight: '500'}} className='navigation-router-navlink-project' to='/works'>
                    <p className='navigation-links'>Projects</p>
                </NavLink>

                <NavLink activeStyle={{fontWeight: '500', color: 'blue'}} className='navigation-router-navlink-contact' to='/contact'>
                    <p className='navigation-links'>Contact</p>
                </NavLink>
            </div>
            <div className='navigation-hamburger--container'>
                <Wrapper onSelection={open}>
                    <Button><AiOutlineMenu className='navigation-hamburger'/></Button>
                    {/* <AiOutlineMenu className='navigation-hamburger'/> */}
                    <Menu className='hamburger-interior-menu'>
                        <div className='hamburger-links--container'>
                            <Link to='/' exact='true'>
                                <MenuItem onSelection={() => !openHamburgerMenu} className='hamburger-links'>Home</MenuItem>
                            </Link>
                            <Link to='/about' exact='true'>
                                <MenuItem onSelection={() => !openHamburgerMenu} className='hamburger-links'>About</MenuItem>
                            </Link>
                            <Link to='/resume' exact='true'>
                                <MenuItem onSelection={() => !openHamburgerMenu} className='hamburger-links'>Resume</MenuItem>
                            </Link>
                            <Link to='/works' exact='true'>
                                <MenuItem onSelection={() => !openHamburgerMenu} className='hamburger-links'>Projects</MenuItem>
                            </Link>
                            <Link to='/contact' exact='true'>
                                <MenuItem onSelection={() => !openHamburgerMenu} className='hamburger-links'>Contact</MenuItem>
                            </Link>
                        </div>
                    </Menu>
                </Wrapper>
            </div>
        </div>
    )
}

export default Navigation;
