import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import logo from './54549-teamwork-project-launch-animation.json';
import './splash.css';

const Splash = () => {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector('#splash-logo'),
            animationData: logo,
        })
    }, [])

    return (
        <div className='splash-page-container'>
            <div className='splash-developer-container'>
                <h1 className='splash-developer'>Michael Tufo</h1>
                <p className='splash-develop-title'>Software Engineer</p>
            </div>

            <div id='splash-logo'>

            </div>
        </div>
    )
}

export default Splash;
